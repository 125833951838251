import React, { useRef, useEffect, useContext } from 'react';
import { ImagePositionContext } from './ImagePositionContext';

const Image = ({id, direction}) => {
    const imageRef = useRef(null);
    const { setImagePosition } = useContext(ImagePositionContext);
    useEffect(() => {
        const position = imageRef.current.getBoundingClientRect();
        setImagePosition(id, position);
    }, []);

    function getZIndex(imgPositiom) {
        if (imgPositiom === direction) {
            return 1;
        } else {
            return 0;
        }        
    }

    function renderImage(p) {
        const style = { 
            height: 'auto',
            width: '100%',
            top: '0',
            left: '0',
            position: 'absolute',
            zIndex: getZIndex(p)
        };

        return (
            <>
                <img style={style} src={`https://web-component-react.now.sh/${id}-${p}.jpg`}></img>
                <br />
            </>
        )
    }

    return (
        <div ref={imageRef} style={{ border: '1px solid #f0f0f0',  position: 'relative', paddingTop: '56.25%'}}>
            {renderImage('c')}            
            {renderImage('r')}
            {renderImage('l')}
            {renderImage('b')}
            {renderImage('t')}
            {renderImage('tl')}
            {renderImage('tr')}
            {renderImage('bl')}
            {renderImage('br')}            
        </div>
    )
}

export default Image;