import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Image from './Image'
import { ImagePositionContext } from './ImagePositionContext';

function App({images = []}) {
  const [directions, setDirections] = useState(_.reduce(images, (result, current) => {
    result[current] = 'c'
    return result;
  }, {}));
  
  const [imagePositions] = useState({});
  
  const setImagePosition = (id, position) => {
    imagePositions[id] = position;
  }
  
  const calcImagePerCordinate = (imagePosition, {x, y}) => {
    const imageTop = imagePosition.top;
    const imageLeft = imagePosition.left;
    const imageWidth = imagePosition.width;
    const imageHeight = imagePosition.height;
    if (x < imageLeft) {
      if (y < imageTop) {
        return 'tl'
      } else if (y > imageTop + imageHeight) {
        return 'bl'
      } else {
        return 'l'
      }
    } else if (x > imageLeft + imageWidth) {        
      if (y < imageTop) {
        return 'tr'
      } else if (y > imageTop + imageHeight) {
        return 'br'
      } else {
        return 'r'
      }
    } else {
      if (y < imageTop) {
        return 't'
      } else {
        return 'b'
      }
    }

  }

  const mousemove = ({clientX, clientY}) => {
    const nextState = _.reduce(images, (result, current) => {
      result[current] = calcImagePerCordinate(imagePositions[current], {x: clientX, y: clientY})
      return result;
    }, {})

    setDirections(prevState => {
      return {...prevState , ...nextState}
    });    
  }

  useEffect(() => {
    window.addEventListener('mousemove', _.debounce(mousemove));
    return () => {
      window.removeEventListener('mousemove', _.debounce(mousemove));
    }
  }, []);

  
  const imageContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(350px,1fr))',
    gridGap: '20px',
    padding: '10px 10px 10px 0'
  }


  return (
    <ImagePositionContext.Provider value={{setImagePosition}}>
        <div>
            <div style={imageContainerStyle}>
              {
                images.map(img => {
                  return <Image id={img} direction={directions[img]} />
                })
              }                                                                                                                                                                  
            </div>      
        </div>      
    </ImagePositionContext.Provider>
  );
}

export default App;
